import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IParsedEvent } from 'client/modules/space/Pages/rooms//SpaceRoomPage';

dayjs.extend(customParseFormat);

export interface IDaysEnum {
  [key: number]: { num: number; short: string; long: string };
}

export interface IFilteredEvent {
  startTime: string;
  endTime: string;
  startTimes: string[];
  endTimes: string[];
  days: string[];
}

export function getStandardDateAndTime(events: IParsedEvent[], thisEvent: IParsedEvent) {
  const daysEnum: IDaysEnum = {
    0: { num: 0, short: 'Sun', long: 'Sunday' },
    1: { num: 1, short: 'Mon', long: 'Monday' },
    2: { num: 2, short: 'Tue', long: 'Tuesday' },
    3: { num: 3, short: 'Wed', long: 'Wednesday' },
    4: { num: 4, short: 'Thu', long: 'Thursday' },
    5: { num: 5, short: 'Fri', long: 'Friday' },
    6: { num: 6, short: 'Sat', long: 'Saturday' },
  };

  const filtered = events
    .filter((val) => val.course_id === thisEvent.course_id)
    .reduce(
      (acc, val) => {
        if (!acc.days.includes(val.day)) acc.days.push(val.day);
        if (!acc.startTimes.includes(val.startTime)) acc.startTimes.push(val.startTime);
        if (!acc.endTimes.includes(val.endTime)) acc.endTimes.push(val.endTime);
        return acc;
      },
      {
        startTime: 'Varies', endTime: 'Varies', days: [], startTimes: [], endTimes: [],
      } as IFilteredEvent,
    );

  filtered.days = filtered.days.sort().map((val) => daysEnum[val as unknown as number].long);
  filtered.startTime = filtered.startTimes.length === 1
    ? dayjs(filtered.startTimes[0].toString().padStart(4, '0'), 'HHmm').format('h:mm A') : 'Varies';
  filtered.endTime = filtered.endTimes.length === 1
    ? dayjs(filtered.endTimes[0].toString().padStart(4, '0'), 'HHmm').format('h:mm A') : 'Varies';

  return filtered;
}
