import React, {
  ChangeEvent, createRef, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// @ts-ignore
import xlsx from '@sheet/dabblefox';
import { IExcelColumn } from 'shared/interfaces/Editor/IExcelColumn';
import { ISheetColumn } from 'shared/interfaces/Editor/ISheetColumn';
import { ExcelColumn } from '../../modules/editor/Utilities/Classes/ExcelColumn';

interface IExcelImporterModal {
  title?: string;
  sampleColumns: IExcelColumn[];
  actualColumns: IExcelColumn[];
  onImport: ({
    rows,
    fileName,
    columnMappings,
  }: {
    rows: any[];
    fileName: string;
    columnMappings: IExcelColumn[];
  }) => Promise<void>;
  handleClose: () => void;
}

export const ExcelImporterModal = observer(
  ({
    title,
    sampleColumns,
    actualColumns,
    onImport,
    handleClose,
  }: IExcelImporterModal) => {
    const [excelColumns, setExcelColumns] = useState<ExcelColumn[]>(
      actualColumns?.length
        ? actualColumns.map((x) => new ExcelColumn(x))
        : sampleColumns.map((x) => new ExcelColumn(x)),
    );
    const [headers, setHeaders] = useState<ISheetColumn[]>([]);
    const [sheet, setSheet] = useState<any>({});
    const [maxRows, setMaxRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasFile, setHasFile] = useState(false);

    const fileInputRef = createRef<HTMLInputElement>();

    useEffect(() => {
      // selectFile();
    }, []);

    function selectFile() {
      if (fileInputRef.current) {
        // @ts-ignore
        fileInputRef.current.value = null;
        fileInputRef.current.click();
      }
    }

    function processFileChange(e: ChangeEvent<HTMLInputElement>) {
      const file = e.target?.files![0];

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent<FileReader>) => {
        const fileData = e.target?.result;
        const workbook = xlsx.read(fileData, { type: 'binary' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        const { headers, maxRow } = convertExcelSheetToRows(firstSheet);
        excelColumns.forEach((excelColumn) => {
          excelColumn.guessHeader(headers);
        });
        setHeaders(headers);
        setLoading(false);
        setHasFile(true);
        setSheet(firstSheet);
        setMaxRows(maxRow);
      };

      function convertExcelSheetToRows(sheet: any) {
        const { sheetColumns, maxRow } = Object.keys(sheet).reduce(
          (acc, cell) => {
            const colMatch = cell.match(/\D/);
            const rowMatch = cell.match(/\d+/);

            if (colMatch && colMatch[0] !== '!') {
              acc.sheetColumns.add(colMatch[0]);
            }
            if (rowMatch && rowMatch[0]) {
              acc.maxRow = acc.maxRow > +rowMatch[0] ? acc.maxRow : +rowMatch[0];
            }

            return acc;
          },
          { sheetColumns: new Set<string>(), maxRow: 0 },
        );

        // Create header values
        const headers = Array.from(sheetColumns).map((column) => ({
          label: sheet[`${column}${1}`]?.v || '',
          value: column,
          examples: [2, 3, 4, 5, 6].map((x) => {
            const cell = sheet[`${column}${x}`];
            if (cell && typeof cell.w === 'string') {
              return cell.w.trim();
            }
            return cell?.w ?? '';
          }),
        }));

        return { headers, maxRow };
      }

      reader.readAsBinaryString(file);
    }

    function processImport() {
      const rows: any[] = [];

      const originalRows = xlsx.utils.sheet_to_json(sheet) as {
        [key: string]: string | number | undefined;
      }[];

      originalRows.map((row) => {
        const newRow: any = {};

        excelColumns
          .filter((column) => column.header?.label && row[column.header.label])
          .forEach((column) => {
            let value: string | number | undefined = row[column.header!.label];
            if (typeof value === 'string') value = value.trim();
            if (column.type === 'number' && typeof value === 'string') value = +value;

            // Handle having a period separator
            const pathParts = column.path.split('.');
            let rowPart = newRow;
            for (let i = 0; i < pathParts.length; i++) {
              if (i === pathParts.length - 1) {
                rowPart[pathParts[i]] = value;
              } else {
                if (rowPart[pathParts[i]] === undefined) {
                  rowPart[pathParts[i]] = {};
                }
                rowPart = rowPart[pathParts[i]];
              }
            }
          });
        rows.push(newRow);
      });

      setLoading(true);
      onImport({
        rows,
        columnMappings: excelColumns,
        fileName: fileInputRef.current?.files![0].name ?? '',
      }).then(() => {
        setLoading(false);
        handleClose();
      });
    }

    function createTemplateExcel() {
      const data: any = [[], []];
      for (let i = 0; i < sampleColumns.length; i++) {
        const column = sampleColumns[i];
        data[0][i] = column.name;
        data[1][i] = column.sample;
      }

      const workbook: any = xlsx.utils.book_new();
      const worksheet: any = xlsx.utils.aoa_to_sheet(data);
      console.log('DATA', data);

      xlsx.utils.book_append_sheet(workbook, worksheet, title);

      xlsx.writeFile(workbook, `${title}.xlsx`, { cellStyles: true });
    }

    return (
      <>
        <Dialog
          fullWidth
          maxWidth="xl"
          open
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {title ?? 'Excel Importer'}
          </DialogTitle>

          <DialogContent>
            {!hasFile ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button variant="contained" onClick={() => selectFile()}>
                  Choose File
                </Button>
                <Button size="small" onClick={() => createTemplateExcel()}>
                  Download Template File
                </Button>
              </div>
            ) : (
              <>
                <Typography gutterBottom>
                  Match the columns from the spreadsheet to the columns in the
                  Sample File
                </Typography>

                <Paper style={{ overflowX: 'auto' }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Target Columns</TableCell>
                        {excelColumns.map((column) => (
                          <TableCell>
                            <div style={{ width: '150px' }}>{column.name}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Available Columns</TableCell>
                        {excelColumns.map((column) => (
                          <TableCell>
                            <Autocomplete
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                              value={
                                headers.find(
                                  (x) => x.value === column.header?.value,
                                ) ?? null
                              }
                              options={headers}
                              onChange={(x: any) => column.setHeader(x)}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[0, 1, 2, 3, 4].map((index) => (
                        <TableRow>
                          <TableCell>
                            Row
                            {index}
                          </TableCell>
                          {excelColumns.map((column, cIndex) => (
                            <ExcelExampleCell
                              key={cIndex}
                              column={column}
                              index={index}
                            />
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </>
            )}
          </DialogContent>

          <DialogActions>
            {hasFile ? (
              <Button variant="contained" onClick={() => selectFile()}>
                Change File
              </Button>
            ) : (
              <></>
            )}
            <div style={{ flex: 1 }} />
            <Button onClick={handleClose}>Cancel</Button>
            <Button autoFocus onClick={processImport} color="primary">
              Process Spreadsheet
            </Button>
          </DialogActions>
        </Dialog>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={fileInputRef}
          accept=".csv,.xlsx,.xls,.xlsm,.tsv"
          onChange={processFileChange}
        />
      </>
    );
  },
);

const ExcelExampleCell = observer(
  ({ column, index }: { column: ExcelColumn; index: number }) => (
    <TableCell>
      {column.header?.examples ? column.header?.examples[index] : undefined}
    </TableCell>
  ),
);
