import axios, { AxiosRequestConfig } from 'axios';
import {
  IAutodeskFolderContentsResponse,
  IAutodeskHub,
  IAutodeskItemTip,
  IAutodeskTopFolderResponse,
  IEditor_OnumaSiteResponse,
  IEditorBannerSyncResponse,
  IEditorBuildingServiceAutodesk,
  IEditorBuildingServiceSeries25,
  IEditorFinish,
  IEditorRoomUse,
  IEditorSeries25Building,
  IEditorSeries25Space,
  IEditorSitePatch,
  IEditorSiteServiceAutodesk,
  IEditorSiteServiceBanner,
  IEditorSiteServiceEthos,
  IEditorSiteServiceOnuma,
  IEditorSiteServiceSeries25,
  IEditorSlab,
  IEditorSpace,
  IEditorSpaceServiceSeries25,
  IPatchEditorFinish,
  IPatchEditorRoomUse,

} from 'client/modules/editor/Utilities/Interfaces';
import { IEditor_Building } from 'shared/interfaces/Editor/IEditor_Building';
import { IClientUser, IPatchUser } from 'shared/interfaces/Admin';
import { IExcelScheduleSource } from 'shared/interfaces/Editor/IExcelScheduleSource';
import { IEditor_Floor, IEditor_Site } from 'shared/interfaces/Editor';
import { ICustodialTask, ICustodialScenario } from 'shared/interfaces/Custodian';
import { CustodialSpace, ICustodialSpaceClient } from '../../modules/custodian/Utilities/Classes/CustodialSpace';
import { ICustodialBuilding } from '../../modules/custodian/Utilities/Classes/CustodialBuilding';
import { ICustodialTeam } from '../../modules/custodian/Utilities/Classes/ICustodialTeam';
import { ICustodialZone, IPatchCustodialZone } from '../../modules/custodian/Utilities/Classes/ICustodialZone';

import { ICustodialLevel } from '../../modules/custodian/Utilities/Classes/ICustodialLevel';
import { ICustodialProfile } from '../../modules/custodian/Utilities/Classes/ICustodialProfile';
import { ExcelScheduleSource } from '../../modules/editor/Utilities/Classes/ExcelScheduleSource';
import { ICustodialCustodian, IPatchCustodian } from '../../modules/custodian/Utilities/Classes/CustodialCustodian';
import { ICustodialTaskFFE } from '../../modules/custodian/Utilities/Classes/ICustodialTaskFFE';
import { ICustodialScenarioTemplateHolder } from '../../modules/custodian/Utilities/Classes/ICustodialScenarioTemplate';
import { IImportEditorSingleSpace } from '../../modules/editor/Components/ImportEditorSpacesDialog';
import { ILog } from '../../modules/admin/Utilities/Interfaces/ILog';
import { IOrganization } from '../../utilities/classes/superAdmin/organization';
import { IMirror } from '../../modules/admin/Utilities/Interfaces/IMirror';

// @ts-ignore
axios.defaults.baseURL = import.meta.env.VITE_API;

export class ApiCalls {
  static GetToken() {
    return window.localStorage.getItem('token');
  }

  static setToken({ token }: { token: string }) {
    window.localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Basic ${token}`;
  }

  public static Custodial = class {
    static GetReport({
      site_id,
      scenario_id,
      reportType,
      query,
    }: {
      site_id: string;
      scenario_id: string;
      reportType: string;
      query: string;
    }) {
      return SimpleGet<any[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/reports/${reportType}?${query}`,
      );
    }

    /* Custodial Custodians */
    static GetCustodialCustodians({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialCustodian[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/custodians`,
      );
    }

    static PostCustodialCustodians({
      site_id,
      scenario_id,
      custodians,
    }: {
      site_id: string;
      scenario_id: string;
      custodians: IPatchCustodian[];
    }) {
      return SimplePost<{
        errors: string[];
        custodians: ICustodialCustodian[];
      }>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/custodians`,
        custodians,
      );
    }

    static DeleteCustodialCustodians({
      site_id,
      scenario_id,
      custodians,
    }: {
      site_id: string;
      scenario_id: string;
      custodians: ICustodialCustodian[];
    }) {
      return SimpleDelete<{
        errors: string[];
        custodians: ICustodialCustodian[];
      }>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/custodians/${custodians
          .map((x) => x.custodian_id)
          .join(',')}`,
      );
    }

    /* Custodial Zones */
    static GetCustodialZones({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialZone[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/zones`,
      );
    }

    static PostCustodialZones({
      site_id,
      scenario_id,
      zones,
    }: {
      site_id: string;
      scenario_id: string;
      zones: IPatchCustodialZone[];
    }) {
      return SimplePost<{ errors: string[]; zones: ICustodialZone[] }>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/zones`,
        zones,
      );
    }

    static PatchCustodialZones({
      site_id,
      scenario_id,
      zones,
    }: {
      site_id: string;
      scenario_id: string;
      zones: IPatchCustodialZone[];
    }) {
      return SimplePatch<{ errors: string[]; zones: ICustodialZone[] }>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/zones`,
        zones,
      );
    }

    static DeleteCustodialZones({
      site_id,
      scenario_id,
      zones,
    }: {
      site_id: string;
      scenario_id: string;
      zones: ICustodialZone[];
    }) {
      return SimpleDelete<{ errors: string[]; zones: ICustodialZone[] }>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/zones/${zones
          .map((x) => x._id)
          .join(',')}`,
      );
    }

    static GetCustodialZoneRoomPdf({
      site_id,
      scenario_id,
      zone_id,
      fileName,
      includeSchedule,
      startDate,
      endDate,
    }: {
      site_id: string;
      scenario_id: string;
      zone_id: string;
      fileName: string;
      includeSchedule?: boolean;
      startDate?: number;
      endDate?: number;
    }) {
      return SimpleDownload(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/pdfs/zones/${zone_id}/spaces?${
          includeSchedule ? 'includeSchedule=true' : ''
        }${startDate ? `&startDate=${startDate}` : ''}${
          includeSchedule ? `&endDate=${endDate}` : ''
        }`,
        fileName,
      );
    }

    static GetCustodialZoneBuildingsPdf({
      site_id,
      scenario_id,
      zone_id,
      fileName,
    }: {
      site_id: string;
      scenario_id: string;
      zone_id: string;
      fileName: string;
    }) {
      return SimpleDownload(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/pdfs/zones/${zone_id}/buildings`,
        fileName,
      );
    }

    static GetCustodialCustodianRoomPdf({
      site_id,
      scenario_id,
      user_id,
      fileName,
      includeSchedule,
      startDate,
      endDate,
    }: {
      site_id: string;
      scenario_id: string;
      user_id: string;
      fileName: string;
      includeSchedule?: boolean;
      startDate?: number;
      endDate?: number;
    }) {
      return SimpleDownload(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/pdfs/custodians/${user_id}/spaces?${
          includeSchedule ? 'includeSchedule=true' : ''
        }${startDate ? `&startDate=${startDate}` : ''}${
          includeSchedule ? `&endDate=${endDate}` : ''
        }`,
        fileName,
      );
    }

    static GetCustodialCustodianBuildingsPdf({
      site_id,
      scenario_id,
      user_id,
      fileName,
    }: {
      site_id: string;
      scenario_id: string;
      user_id: string;
      fileName: string;
    }) {
      return SimpleDownload(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/pdfs/custodians/${user_id}/buildings`,
        fileName,
      );
    }

    static GetCustodialBuildings({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialBuilding[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/buildings`,
      );
    }

    static GetCustodialSpaces({
      site_id,
      bldg_id,
      scenario_id,
    }: {
      site_id: string;
      bldg_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialSpaceClient[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/buildings/${bldg_id}/spaces`,
      );
    }

    static GetCustodialProfiles({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialProfile[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/profiles`,
      );
    }

    static GetCustodialTasks({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialTask[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/tasks`,
      );
    }

    static CreateCustodialTasks({
      site_id,
      scenario_id,
      tasks,
    }: {
      site_id: string;
      scenario_id: string;
      tasks: ICustodialTask[];
    }) {
      return SimplePost<ICustodialTask[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/tasks`,
        tasks,
      );
    }

    static GetCustodialLevels({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialLevel[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/levels`,
      );
    }

    static CreateCustodialLevels({
      site_id,
      scenario_id,
      levels,
    }: {
      site_id: string;
      scenario_id: string;
      levels: ICustodialLevel[];
    }) {
      return SimplePost<ICustodialLevel[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/levels`,
        levels,
      );
    }

    static GetCustodialTeams({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialTeam[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/teams`,
      );
    }

    static CreateCustodialTeams({
      site_id,
      scenario_id,
      teams,
    }: {
      site_id: string;
      scenario_id: string;
      teams: ICustodialTeam[];
    }) {
      return SimplePost<ICustodialTeam[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/teams`,
        teams,
      );
    }

    /* Scenarios */
    static GetCustodialScenarios({ site_id }: { site_id: string }) {
      return SimpleGet<ICustodialScenario[]>(
        `api/v0/custodian/${site_id}/scenarios`,
      );
    }

    static GetCustodialTemplateScenarios({ site_id }: { site_id: string }) {
      return SimpleGet<ICustodialScenarioTemplateHolder>(
        `api/v0/custodian/${site_id}/scenarioTemplates`,
      );
    }

    static GetCustodialScenario({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialScenario>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}`,
      );
    }

    static CreateScenario({
      site_id,
      name,
      templateScenario_id,
      copyCustodians,
      copyZones,
      copySpaces,
      copyPermissions,
    }: {
      site_id: string;
      name: string;
      templateScenario_id: string;
      copyCustodians: boolean;
      copyZones: boolean;
      copySpaces: boolean;
      copyPermissions: boolean;
    }) {
      return SimplePost<ICustodialScenario>(
        `api/v0/custodian/${site_id}/scenarios`,
        {
          name,
          templateScenario_id,
          copyCustodians,
          copyZones,
          copySpaces,
          copyPermissions,
        },
      );
    }

    static UpdateCustodialSpaces(params: {
      site_id: string;
      scenario_id: string;
      bldg_id: string;
      space_ids: string[];
      level_id?: string;
      profile_id?: string;
      zone_id?: string;
      team_id?: string;
    }) {
      return SimplePost<ICustodialSpaceClient[]>(
        `api/v0/custodian/${params.site_id}/scenarios/${params.scenario_id}/buildings/${params.bldg_id}/spaces`,
        {
          space_ids: params.space_ids,
          level_id: params.level_id,
          profile_id: params.profile_id,
          zone_id: params.zone_id,
          team_id: params.team_id,
        },
      );
    }

    static PutCustodialSpace({
      site_id,
      scenario_id,
      bldg_id,
      space,
    }: {
      site_id: string;
      scenario_id: string;
      bldg_id: string;
      space: CustodialSpace;
    }) {
      return SimplePut<ICustodialSpaceClient>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/buildings/${bldg_id}/spaces/${space._id}`,
        space,
      );
    }

    static GetCustodialTaskFFE({
      site_id,
      scenario_id,
    }: {
      site_id: string;
      scenario_id: string;
    }) {
      return SimpleGet<ICustodialTaskFFE[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/ffe`,
      );
    }

    static CreateCustodialProfiles({
      site_id,
      scenario_id,
      profiles,
    }: {
      site_id: string;
      scenario_id: string;
      profiles: ICustodialProfile[];
    }) {
      return SimplePost<ICustodialProfile[]>(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/profiles`,
        profiles,
      );
    }

    static ApplyRoomUses({
      site_id,
      scenario_id,
      bldg_ids,
    }: {
      site_id: string;
      scenario_id: string;
      bldg_ids?: string[];
    }) {
      return SimplePost(
        `api/v0/custodian/${site_id}/scenarios/${scenario_id}/roomUses/apply?${
          bldg_ids?.length ? `building_ids=${bldg_ids?.join(',')}` : ''
        }`,
      );
    }
  };

  public static Onuma = class {
    static PullLatestBuildingSpaces({
      site_id,
      bldg_id,
    }: {
      site_id: string;
      bldg_id: string;
    }) {
      return SimplePost<IEditorSpace[]>(
        `api/v0/editor/${site_id}/onuma/buildings/${bldg_id}/spaces`,
      );
    }

    static PullLatestBuildings({ site_id }: { site_id: string }) {
      return SimplePost<IEditor_OnumaSiteResponse>(
        `api/v0/editor/${site_id}/onuma/buildings`,
      );
    }

    static PullLatestAll({ site_id }: { site_id: string }) {
      return SimplePost(`api/v0/editor/${site_id}/onuma/updateAll`);
    }
  };

  public static Banner = class {
    static PatchSite({
      site_id,
      service,
    }: {
      site_id: string;
      service: IEditorSiteServiceBanner;
    }) {
      return SimplePatch<IEditor_Site>(
        `api/v0/editor/${site_id}/schedule/banner`,
        service,
      );
    }
  };

  public static Ethos = class {
    static PatchSite({
      site_id,
      service,
    }: {
      site_id: string;
      service: IEditorSiteServiceEthos;
    }) {
      return SimplePatch<IEditor_Site>(
        `api/v0/editor/${site_id}/schedule/ethos`,
        service,
      );
    }

    static CreateClassesFromMirror({ site_id }: { site_id: string }) {
      return SimplePost(
        `api/v0/editor/${site_id}/schedule/ethos/coursesFromMirror`,
        {},
      );
    }
  };

  public static Series25 = class {
    static PatchSite({
      site_id,
      service,
    }: {
      site_id: string;
      service: IEditorSiteServiceSeries25;
    }) {
      return SimplePatch<IEditor_Site>(
        `api/v0/editor/${site_id}/schedule/series25`,
        service,
      );
    }

    static PatchBuilding({
      site_id,
      bldg_id,
      service,
    }: {
      site_id: string;
      bldg_id: string;
      service: IEditorBuildingServiceSeries25;
    }) {
      return SimplePatch<IEditor_Building>(
        `api/v0/editor/${site_id}/schedule/series25/buildings/${bldg_id}`,
        service,
      );
    }

    static PatchSpace({
      site_id,
      bldg_id,
      space_id,
      service,
    }: {
      site_id: string;
      bldg_id: string;
      space_id: string;
      service: IEditorSpaceServiceSeries25;
    }) {
      return SimplePatch<IEditor_Building>(
        `api/v0/editor/${site_id}/schedule/series25/buildings/${bldg_id}/spaces/${space_id}`,
        service,
      );
    }

    static GetBuildings({ site_id }: { site_id: string }) {
      return SimpleGet<IEditorSeries25Building[]>(
        `api/v0/editor/${site_id}/schedule/series25/buildings`,
      );
    }

    static GetBuildingSpaces({
      site_id,
      bldg_id,
    }: {
      site_id: string;
      bldg_id: string;
    }) {
      return SimpleGet<IEditorSeries25Space[]>(
        `api/v0/editor/${site_id}/schedule/series25/buildings/${bldg_id}/spaces`,
      );
    }

    static SyncSite({
      site_id,
      startDate,
      endDate,
    }: {
      site_id: string;
      startDate: number;
      endDate: number;
    }) {
      return SimplePost<{ results: any[]; errors: any[] }>(
        `api/v0/editor/${site_id}/schedule/series25/schedule?startDate=${startDate}&endDate=${endDate}`,
      );
    }

    static SyncBuilding({
      site_id,
      bldg_id,
      startDate,
      endDate,
    }: {
      site_id: string;
      bldg_id: string;
      startDate: number;
      endDate: number;
    }) {
      return SimplePost<{ results: any[]; errors: any[] }>(
        `api/v0/editor/${site_id}/schedule/series25/buildings/${bldg_id}/schedule?startDate=${startDate}&endDate=${endDate}`,
      );
    }
  };

  public static Editor = class {
    static CreateSite({ site }: { site: IEditor_Site }) {
      return SimplePost<IEditor_Site>('api/v0/location/sites', site);
    }

    static PatchSite({
      site_id,
      site,
    }: {
      site_id: string;
      site: IEditorSitePatch;
    }) {
      return SimplePatch<IEditor_Site>(`api/v0/location/sites/${site_id}`, site);
    }

    static GetEditorSites() {
      return SimpleGet<IEditor_Site[]>('api/v0/location/sites');
    }

    static UpdateSites({ sites }: { sites: Partial<IEditor_Site>[] }) {
      return SimplePatch<IEditor_Site[]>('api/v0/location/sites', sites);
    }

    static DeleteSites({ sites }: { sites: IEditor_Site[] }) {
      return SimpleDelete<IEditor_Site[]>(
        `api/v0/location/sites/${sites.map((x) => x._id).join(',')}`,
      );
    }

    static GetEditorSlabs({
      site_id,
      bldg_id,
    }: {
      site_id: string;
      bldg_id: string;
    }) {
      return SimpleGet<IEditorSlab[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/slabs`,
      );
    }

    static CreateEditorSlabs({
      site_id,
      bldg_id,
      slabs,
    }: {
      site_id: string;
      bldg_id: string;
      slabs: IEditorSlab[];
    }) {
      return SimplePost<IEditorSlab[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/slabs`,
        slabs,
      );
    }

    static UpdateEditorSlabs({
      site_id,
      bldg_id,
      slabs,
    }: {
      site_id: string;
      bldg_id: string;
      slabs: any[];
    }) {
      return SimplePatch<IEditorSlab[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/slabs`,
        slabs,
      );
    }

    static DeleteEditorSlabs({
      site_id,
      bldg_id,
      slab_ids,
    }: {
      site_id: string;
      bldg_id: string;
      slab_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces/${slab_ids.join(
          ',',
        )}`,
      );
    }

    static GetEditorFloors({
      site_id,
      bldg_id,
    }: {
      site_id: string;
      bldg_id: string;
    }) {
      return SimpleGet<IEditor_Floor[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/floors`,
      );
    }

    static CreateEditorFloors({
      site_id,
      bldg_id,
      floors,
    }: {
      site_id: string;
      bldg_id: string;
      floors: Partial<IEditor_Floor>[];
    }) {
      return SimplePost<Partial<IEditor_Floor>[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/floors`,
        floors,
      );
    }

    static UpdateEditorFloors({
      site_id,
      bldg_id,
      floors,
    }: {
      site_id: string;
      bldg_id: string;
      floors: Partial<IEditor_Floor>[];
    }) {
      return SimplePatch<Partial<IEditor_Floor>[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/floors`,
        floors,
      );
    }

    static DeleteEditorFloors({
      site_id,
      bldg_id,
      floor_ids,
    }: {
      site_id: string;
      bldg_id: string;
      floor_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces/${floor_ids.join(
          ',',
        )}`,
      );
    }

    static GetEditorSpaces({
      site_id,
      bldg_id,
    }: {
      site_id: string;
      bldg_id: string;
    }) {
      return SimpleGet<IEditorSpace[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces?fields=name,number,localID,alternateNames,roomUse,finishes,area,stations,map,representation,floorIndex,services,notes`,
      );
    }

    static GetEditorBuildings({ site_id }: { site_id: string }) {
      return SimpleGet<IEditor_Building[]>(
        `api/v0/location/${site_id}/buildings?fields=name,localID,number,alternateNames,services`,
      );
    }

    static GetFlooring({ site_id }: { site_id: string }) {
      return SimpleGet<IEditorFinish[]>(`api/v0/editor/${site_id}/flooring`);
    }

    static UpdateFlooring({
      site_id,
      floorMaterials,
    }: {
      site_id: string;
      floorMaterials: IPatchEditorFinish[];
    }) {
      return SimplePatch<IEditorFinish[]>(
        `api/v0/editor/${site_id}/flooring`,
        floorMaterials,
      );
    }

    static CreateFlooring({
      site_id,
      floorMaterials,
    }: {
      site_id: string;
      floorMaterials: IPatchEditorFinish[];
    }) {
      return SimplePost<IEditorFinish[]>(
        `api/v0/editor/${site_id}/flooring`,
        floorMaterials,
      );
    }

    static DeleteFlooring({
      site_id,
      floorMaterial_ids,
    }: {
      site_id: string;
      floorMaterial_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/editor/${site_id}/flooring/${floorMaterial_ids.join(',')}`,
      );
    }

    static MergeFlooring({
      site_id,
      floorMaterial_ids,
      flooring,
    }: {
      site_id: string;
      floorMaterial_ids: string[];
      flooring: IPatchEditorFinish;
    }) {
      return SimplePost<IEditorFinish[]>(
        `api/v0/editor/${site_id}/flooring/${floorMaterial_ids.join(
          ',',
        )}/merge`,
        flooring,
      );
    }

    static GetRoomUses({ site_id }: { site_id: string }) {
      return SimpleGet<IEditorRoomUse[]>(`api/v0/editor/${site_id}/roomUses`);
    }

    static UpdateRoomUses({
      site_id,
      roomUses,
    }: {
      site_id: string;
      roomUses: IPatchEditorRoomUse[];
    }) {
      return SimplePatch<IEditorRoomUse[]>(
        `api/v0/editor/${site_id}/roomUses`,
        roomUses,
      );
    }

    static CreateRoomUses({
      site_id,
      roomUses,
    }: {
      site_id: string;
      roomUses: IPatchEditorRoomUse[];
    }) {
      return SimplePost<IEditorRoomUse[]>(
        `api/v0/editor/${site_id}/roomUses`,
        roomUses,
      );
    }

    static DeleteRoomUses({
      site_id,
      roomUse_ids,
    }: {
      site_id: string;
      roomUse_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/editor/${site_id}/roomUses/${roomUse_ids.join(',')}`,
      );
    }

    static MergeRoomUses({
      site_id,
      roomUse_ids,
      roomUse,
    }: {
      site_id: string;
      roomUse_ids: string[];
      roomUse: IPatchEditorRoomUse;
    }) {
      return SimplePost<IEditorRoomUse[]>(
        `api/v0/editor/${site_id}/roomUses/${roomUse_ids.join(',')}/merge`,
        roomUse,
      );
    }

    static UpdateSpaces({
      site_id,
      bldg_id,
      spaces,
    }: {
      site_id: string;
      bldg_id: string;
      spaces: any[];
    }) {
      return SimplePatch<IEditorSpace[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces?fields=localID,name,number,alternateNames,roomUse,finishes,area,stations,map,representation,floorIndex,services`,
        spaces,
      );
    }

    static DeleteSpaces({
      site_id,
      bldg_id,
      space_ids,
    }: {
      site_id: string;
      bldg_id: string;
      space_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces/${space_ids.join(
          ',',
        )}`,
      );
    }

    static CreateSpaces({
      site_id,
      bldg_id,
      spaces,
    }: {
      site_id: string;
      bldg_id: string;
      spaces: IImportEditorSingleSpace[];
    }) {
      return SimplePost<IEditorSpace[]>(
        `api/v0/location/${site_id}/buildings/${bldg_id}/spaces`,
        spaces,
      );
    }

    static UpdateBuildings({
      site_id,
      buildings,
    }: {
      site_id: string;
      buildings: any[];
    }) {
      return SimplePatch<IEditor_Building[]>(
        `api/v0/location/${site_id}/buildings`,
        buildings,
      );
    }

    static DeleteBuildings({
      site_id,
      bldg_ids,
    }: {
      site_id: string;
      bldg_ids: string[];
    }) {
      return SimpleDelete(
        `api/v0/location/${site_id}/buildings/${bldg_ids.join(',')}`,
      );
    }

    static CreateBuildings({
      site_id,
      buildings,
    }: {
      site_id: string;
      buildings: any[];
    }) {
      return SimplePost<IEditor_Building[]>(
        `api/v0/location/${site_id}/buildings`,
        buildings,
      );
    }

    static GetExcelSchedules({ site_id }: { site_id: string }) {
      return SimpleGet<IExcelScheduleSource[]>(
        `api/v0/editor/${site_id}/schedule/excel`,
      );
    }

    static CreateExcelSchedule(excel: ExcelScheduleSource) {
      return SimplePost<{ errors: any[]; successCount: number }>(
        `api/v0/editor/${excel.site_id}/schedule/excel`,
        excel.patchBody,
      );
    }

    static TriggerSftpSchedule(excel: ExcelScheduleSource) {
      return SimplePost<{ errors: any[]; successCount: number }>(
        `api/v0/editor/${excel.site_id}/schedule/${excel._id}/sftp/trigger`,
      );
    }

    static UpdateExcelSchedule(excel: ExcelScheduleSource) {
      return SimplePatch<{ errors: any[]; successCount: number }>(
        `api/v0/editor/${excel.site_id}/schedule/excel/${excel._id}`,
        excel.patchBody,
      );
    }

    static DeleteExcelSchedule(excel: ExcelScheduleSource) {
      return SimpleDelete(
        `api/v0/editor/${excel.site_id}/schedule/excel/${excel._id}`,
      );
    }

    static PullBuildingBannerSchedule({
      site_id,
      building_id,
      startDate,
      endDate,
    }: {
      site_id: string;
      building_id: string;
      startDate: string;
      endDate: string;
    }) {
      return SimplePost<IEditorBannerSyncResponse>(
        `api/v0/editor/${site_id}/schedule/banner/buildings/${building_id}/schedule?startDate=${startDate}&endDate=${endDate}`,
      );
    }
  };

  public static Admin = class {
    static GetOrg() {
      return SimpleGet<IOrganization>('api/v0/account/organizations/current');
    }

    static GetOrgLogs({
      org_id,
      limit,
      offset,
      startDate,
      endDate,
    }: {
      org_id: string;
      limit?: number;
      offset?: number;
      startDate?: number;
      endDate?: number;
    }) {
      return SimpleGet<ILog[]>(`api/v0/logs/organizations/${org_id}`, {
        params: {
          limit,
          offset,
          startDate,
          endDate,
        },
      });
    }

    static GetOrgUserLogs({
      org_id,
      user_id,
      limit,
      offset,
      startDate,
      endDate,
    }: {
      org_id: string;
      user_id: string;
      limit?: number;
      offset?: number;
      startDate?: number;
      endDate?: number;
    }) {
      return SimpleGet<ILog[]>(
        `api/v0/logs/organizations/${org_id}/users/${user_id}`,
        {
          params: {
            limit,
            offset,
            startDate,
            endDate,
          },
        },
      );
    }

    static GetUsers() {
      return SimpleGet<IClientUser[]>('api/v0/account/users');
    }

    static CreateUsers({ users }: { users: Partial<IPatchUser>[] }) {
      return SimplePost<{ users: IClientUser[]; errors: string[] }>(
        'api/v0/account/users',
        users,
      );
    }

    static UpdateUsers({ users }: { users: Partial<IPatchUser>[] }) {
      return SimplePatch<{ users: IClientUser[]; errors: string[] }>(
        'api/v0/account/users',
        users,
      );
    }

    static DeleteUsers({ user_ids }: { user_ids: string[] }) {
      return SimpleDelete(`api/v0/account/users/${user_ids.join(',')}`);
    }

    static Mirrors = class {
      static GetMirrors() {
        return SimpleGet('api/v0/admin/mirrors');
      }

      static CreateMirror({ mirror }: { mirror: Partial<IMirror> }) {
        return SimplePost('api/v0/admin/mirrors', mirror);
      }

      static PatchMirror({ mirror }: { mirror: Partial<IMirror> }) {
        return SimplePatch(`api/v0/admin/mirrors/${mirror._id}`, mirror);
      }

      static DeleteMirror({ mirror_id }: { mirror_id: string }) {
        return SimpleGet(`api/v0/admin/mirrors/${mirror_id}`);
      }
    };
  };

  public static Autodesk = class {
    static GetAuthUrl({ site_id }: { site_id: string }) {
      return SimpleGet<string>(`api/v0/autodesk/sites/${site_id}/authUrl`);
    }

    static PatchSite({
      site_id,
      service,
    }: {
      site_id: string;
      service: IEditorSiteServiceAutodesk;
    }) {
      return SimplePatch<IEditor_Site>(
        `api/v0/editor/${site_id}/services/autodesk`,
        service,
      );
    }

    static PatchBuilding({
      site_id,
      bldg_id,
      service,
    }: {
      site_id: string;
      bldg_id: string;
      service: IEditorBuildingServiceAutodesk;
    }) {
      return SimplePatch<IEditor_Building>(
        `api/v0/editor/${site_id}/services/autodesk/buildings/${bldg_id}`,
        service,
      );
    }

    static GetHubs({ site_id }: { site_id: string }) {
      return SimpleGet<IAutodeskHub[]>(`api/v0/autodesk/sites/${site_id}/hubs`);
    }

    static GetProjects({ site_id, hubId }: { site_id: string; hubId: string }) {
      return SimpleGet<IAutodeskHub[]>(
        `api/v0/autodesk/sites/${site_id}/hubs/${hubId}/projects`,
      );
    }

    static GetViewerToken({ site_id }: { site_id: string }) {
      return SimpleGet<{ access_token: string; expires_in: number }>(
        `api/v0/autodesk/sites/${site_id}/viewerToken`,
      );
    }

    static GetItemTip({
      site_id,
      itemId,
    }: {
      site_id: string;
      itemId: string;
    }) {
      return SimpleGet<IAutodeskItemTip>(
        `api/v0/autodesk/sites/${site_id}/items/${itemId}`,
      );
    }

    static GetTopFolders({
      site_id,
      projectId,
    }: {
      site_id: string;
      projectId: string;
    }) {
      return SimpleGet<IAutodeskTopFolderResponse>(
        `api/v0/autodesk/sites/${site_id}/projects/${projectId}/folders`,
      );
    }

    static GetFolderContents({
      site_id,
      projectId,
      folderId,
    }: {
      site_id: string;
      projectId: string;
      folderId: string;
    }) {
      return SimpleGet<IAutodeskFolderContentsResponse>(
        `api/v0/autodesk/sites/${site_id}/projects/${projectId}/folders/${folderId}/contents`,
      );
    }
  };
}

function SimpleGet<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .get(`/${url}`, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function SimplePost<T>(url: string, body?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .post(`/${url}`, body, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function SimplePatch<T>(url: string, body?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/${url}`, body, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function SimplePut<T>(url: string, body?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .put(`/${url}`, body, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function SimpleDelete<T>(url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/${url}`, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function SimpleDownload(url: string, filename: string): Promise<void> {
  return new Promise((resolve, reject) => {
    axios
      .get(`/${url}`, {
        headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        responseType: 'blob',
      })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}
